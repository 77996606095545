import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import ProjectCard  from "./ProjectCard";
import projImg1 from "../assets/img/etcdd.png";
import projImg2 from "../assets/img/powerplay.png";
import projImg3 from "../assets/img/cryptoscreener.png";
import projImg4 from "../assets/img/emlaksoft.png";
import projImg5 from "../assets/img/arsos.png";
import projImg9 from "../assets/img/league.png";
import projImg10 from "../assets/img/mke.png";
import projImg11 from "../assets/img/ailem.png";
import projImg12 from "../assets/img/kontrol.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

const Projects = () => {

    const projects = [
        {
            title: "Power Play App",
            environments: "React Native, RESTFUL, Push Notification, Context API.",
            description: "This application is an application where the sports leagues of the countries are followed and coupons are made.",
            imgUrl: projImg2,
        },
        {
            title: "Crypto Screener",
            environments: "React Native, RESTFUL, Context API, Google Admob, Firebase Realtime.",
            description: "It is a mobile application that you can follow crypto with instant data.",
            imgUrl: projImg3,
        },
        {
            title: "League Live Score",
            environments: "React Native, RESTFUL, Context API, Google Admob.",
            description: "It is an application that shows the live scores of the leagues of the countries..",
            imgUrl: projImg9,
        },
    ];

    const projects2 = [
        {
            title: "EmlakSoft",
            environments: "React Native, RESTFUL, MOBX, One Signal.",
            description: "Emlak Soft is real estate application. It has a role-based interface with a single login. For end users, an interface where they can view postings and real estate profiles is encountered. An interface is available for authorized persons to add, view and edit advertisements.",
            imgUrl: projImg4,
        },
        {
            title: "Arsos Life",
            environments: "React Native, Google Maps,RESTFUL, MOBX.",
            description: "It is a sports and life application. It is a mobile application where the end user can make a private lesson appointment by choosing maps and the trainers around him according to the days when they are available, make the payments, cancel the lesson, enter the sports centers with a QR code and use it in three languages.",
            imgUrl: projImg5,
        },
        {
            title: "E-TCDD",
            environments:"React Native, RESTFUL, Push Notification, Google Maps, Context API.",
            description: "It is an application used by the personnel of the state railways institution." +
                "This application has DocumentNet, Personnel Information, Financial Status, Budget, Announcements, Train Information modules.",
            imgUrl: projImg1,
        }
    ];

    const projects3 = [
        {
            title: "TCDD Maintenance Control Application",
            environments:"React Native, RESTFUL, Google Maps, Context API.",
            description: "This application is a mobile application where the maintenance of construction equipment used in railways is provided.",
            imgUrl: projImg12,
        },
        {
            title: "MKE Handheld Terminal Mobile Application",
            environments:"React Native, Graphql, Redux-Toolkit.",
            description: "ERP Mobile is a mobile application developed for Makina Kimya Endüstri to be managed and tracked by factory employees. The content of the application includes ERP processes.",
            imgUrl: projImg10,
        },
        {
            title: "Video Call Web",
            environments:"React, WebSocket API, Context API, Typescript, Tailwindcss, Bootstrap.",
            description: "It is a web application developed with React, where users speaking in sign language can video call with call centers. I contributed to the development of the application.",
            imgUrl: projImg11,
        }
    ];

    return (
        <section className="project" id="project">
            <Container>
                <Row>
                    <Col size={12}>
                        <TrackVisibility>
                            {({ isVisible }) =>
                                <div>
                                    <h2>Projects</h2>
                                    <p>The projects I have developed are as follows.</p>
                                    <Tab.Container id="projects-tabs" defaultActiveKey="first">
                                        <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Tab 1</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Tab 2</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">Tab 3</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content id="slideInUp">
                                            <Tab.Pane eventKey="first">
                                                <Row>
                                                    {
                                                        projects.map((project, index) => {
                                                            return (
                                                                <ProjectCard
                                                                    key={index}
                                                                    {...project}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <Row>
                                                    {
                                                        projects2.map((project, index) => {
                                                            return (
                                                                <ProjectCard
                                                                    key={index}
                                                                    {...project}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <Row>
                                                    {
                                                        projects3.map((project, index) => {
                                                            return (
                                                                <ProjectCard
                                                                    key={index}
                                                                    {...project}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2}></img>
        </section>
    )
}
export default Projects;
