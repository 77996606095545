import {useState, useEffect} from "react";
import {Navbar, Nav, Container} from "react-bootstrap";
import navIcon1 from '../assets/img/linkedin.svg';
import navIcon2 from '../assets/img/gmail.png';
import navIcon3 from '../assets/img/github.png';
import {
    BrowserRouter as Router
} from "react-router-dom";

const NavBarNavigation = () => {

    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }

    return (
        <Router>
            <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
                <Container>
                    <Navbar.Brand href="/">
                        <h1 className="text-white" >BEYZA NUR YANAR</h1>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span className="navbar-toggler-icon"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="#home"
                                      className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'}
                                      onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                            <Nav.Link href="#skills"
                                      className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'}
                                      onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                            <Nav.Link href="#project"
                                      className={activeLink === 'project' ? 'active navbar-link' : 'navbar-link'}
                                      onClick={() => onUpdateActiveLink('project')}>Projects</Nav.Link>
                        </Nav>
                        <span className="navbar-text">
              <div className="social-icon">

                    <a href="https://www.linkedin.com/in/beyza-nur-yanar-327236208/"><img src={navIcon1} alt="Icon"/></a>
                    <a href="mailto:byanar69@gmail.com"><img src={navIcon2} alt="Icon"/></a>
                    <a href="https://github.com/beyzanuryanar"><img src={navIcon3} alt="Icon"/></a>
              </div>
            </span>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Router>
    )
}

export default NavBarNavigation;
