import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Banner, NavBarNavigation, Skills, Projects, Footer} from "./components";

function App() {
    return (
        <div className="App">
            <NavBarNavigation/>
            <Banner/>
            <Skills/>
            <Projects/>
            <Footer/>
        </div>
    );
}

export default App;
